export const cookieBannder = () => {
  const rootSelector = '.js-cookiebanner';
  const bannerButtonSelector = '.js-cookiebanner-button';

  let storageKey = 'cookie-is-accepted';

  let cookieIsAccepted = localStorage.getItem(storageKey) === 'true';

  console.log(cookieIsAccepted);

  if (cookieIsAccepted) return

  const cookieBannerElem = document.querySelector(rootSelector)

  cookieBannerElem.classList.add('shown');

  document.querySelector(bannerButtonSelector).addEventListener('click', event => {

    cookieIsAccepted = true;

    localStorage.setItem(storageKey, cookieIsAccepted)

    cookieBannerElem.classList.remove('shown');
  })
}