import { consultFormSubmit, partnerFormSubmit } from "./form";
import { maskInit } from "./phoneMask";

export const modalInit = (modalId, show, hide) => {
	let myModal = document.getElementById(modalId);

	if (!!myModal) {
		myModal.addEventListener("show.bs.modal", function (event) {
			let pageWrapper = document.querySelector(".page-wrapper");
	
			if (pageWrapper) {
				pageWrapper.classList.add("blur");
				if (show) show();
			}
		});
	
		myModal.addEventListener("hide.bs.modal", function (event) {
			let pageWrapper = document.querySelector(".page-wrapper");
	
			if (pageWrapper) {
				pageWrapper.classList.remove("blur");
				if (hide) hide();
			}
		});
	
		return myModal;
	}
};

export const modalInitEvent = (modalEl) => {
	modalEl.addEventListener("show.bs.modal", function (event) {
		let pageWrapper = document.querySelector(".page-wrapper");

		if (pageWrapper) {
			pageWrapper.classList.add("blur");
		}
	});

	modalEl.addEventListener("hide.bs.modal", function (event) {
		let pageWrapper = document.querySelector(".page-wrapper");

		if (pageWrapper) {
			pageWrapper.classList.remove("blur");
		}
	});
};

export const modalConsult = () => {
	const show = () => {
		consultFormSubmit("js-consult-form-modal", true);
		maskInit("consultPhone");
	};
	modalInit("consultBackdrop", show);
};

export const modalPartner = () => {
	const show = () => {
		partnerFormSubmit();
		maskInit("partnerPhone");
	};
	modalInit("partnerBackdrop", show);
};
