export const openCustomerMenu = () => {
    const customerElement = document.getElementById("customer");
    const contractorElement = document.getElementById("contractor");
    const customerContentElement = document.getElementById("customer-content");
    const contractorContentElement = document.getElementById("contractor-content");
    const customerImgElement = document.getElementById("customer-img");
    const contractorImgElement = document.getElementById("contractor-img");

    const addHoveredClass = (elementAdd, elementRemove, className) => {
        elementAdd.classList.add(className);
        elementRemove.classList.remove(className);
    };
    if(!!customerElement) {
        customerElement.classList.add("hovered");
    }

    if(!!customerContentElement) {
        customerContentElement.classList.add("hovered-content");
    }

    if (!!customerImgElement) {
        customerImgElement.classList.add("hovered-img");
    }

    if(!!customerElement) {
        customerElement.addEventListener("mouseover", () => {
            addHoveredClass(customerElement, contractorElement, "hovered");
            addHoveredClass(customerContentElement, contractorContentElement, "hovered-content");
            addHoveredClass(customerImgElement, contractorImgElement, "hovered-img");
        });
    }
    if(!!contractorElement) {
        contractorElement.addEventListener("mouseover", () => {
            addHoveredClass(contractorElement, customerElement, "hovered");
            addHoveredClass(contractorContentElement, customerContentElement, "hovered-content");
            addHoveredClass(contractorImgElement, customerImgElement, "hovered-img");
        });
    }
};