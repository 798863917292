import { startAppear, appearOnScroll } from "./animation/appears";
import { openCustomerMenu } from "./animation/customer-menu";
import { ScrollUp } from "./animation/scroll-up";
import { СollapseWrapper } from "./animation/collapse-wrapper";
import { SwiperReview, SwiperCycles } from "./ui/swiper";
import { modalConsult, modalPartner } from "./ui/modal";
import { headerFunctional } from "./ui/headerFunctional";
import { cookieBannder } from "./ui/cookieBanner";
import { consultFormSubmit } from "./ui/form";
import { maskInit } from "./ui/phoneMask";

startAppear();
appearOnScroll();
СollapseWrapper();
SwiperReview();
SwiperCycles();

document.addEventListener("DOMContentLoaded", () => {
	openCustomerMenu();
	consultFormSubmit("js-consult-form");
	maskInit("sectionPhone");
	modalConsult();
	modalPartner();
	headerFunctional();
	cookieBannder();
	ScrollUp();
});
