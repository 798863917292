export const headerFunctional = () => {
	const headerLinkProductSelector = ".js-header-link-product";
	const headerLinkProductBlockSelector = ".js-header-link-product-block";

	const headerMenuSelector = ".js-header-menu";
	const headerMenuButtonSelector = ".js-header-menu-button";
	const headerMenuContentSelector = ".js-header-menu-content";

	const headerProductLink = document.querySelector(headerLinkProductSelector);
	const headerMenu = document.querySelector(headerMenuSelector);

	let currentLink = null;

	// console.log(headerProductLink);
	if (!!headerProductLink) {
		headerProductLink.addEventListener("mouseover", (event) => {
			console.log(event.target.closest(headerLinkProductSelector));

			if (!event.target.closest(headerLinkProductSelector)) return;

			const headerMenu = event.target.closest(headerLinkProductSelector);
			const headerMenuBlock = headerMenu.querySelector(
				headerLinkProductBlockSelector,
			);

			currentLink = headerMenu;

			headerMenu.classList.add("is-active");
			headerMenuBlock.classList.add("is-active");
		});

		headerProductLink.addEventListener("mouseout", () => {
			console.log("mouseout");

			const headerMenuBlock = currentLink.querySelector(
				headerLinkProductBlockSelector,
			);

			currentLink.classList.remove("is-active");
			headerMenuBlock.classList.remove("is-active");
		});
	}

	if (!!headerMenu) {
		headerMenu.addEventListener("click", (event) => {
			if (!event.target.closest(headerMenuButtonSelector)) return;

			const menu = event.target.closest(headerMenuButtonSelector).parentNode;
			const button = menu.querySelector(headerMenuButtonSelector);
			const content = menu.querySelector(headerMenuContentSelector);

			menu.classList.toggle("is-active");
			button.classList.toggle("is-active");
			content.classList.toggle("is-active");
		});
	}

	window.addEventListener("scroll", () => {
		const menu = document.querySelector(headerMenuButtonSelector).parentNode;
		const button = menu.querySelector(headerMenuButtonSelector);
		const content = menu.querySelector(headerMenuContentSelector);

		if (menu && menu.classList.contains("is-active")) {
			menu.classList.remove("is-active");
		}

		if (button && button.classList.contains("is-active")) {
			button.classList.remove("is-active");
		}

		if (content && content.classList.contains("is-active")) {
			content.classList.remove("is-active");
		}
	});
};
