import { modalInitEvent } from "./modal";

export const consultFormSubmit = (id, modal) => {
	const form = document.getElementById(id);
	if (!!form) {
		form.onsubmit = (event) => {
			event.preventDefault();
			const formData = new FormData(form);
			let url = "https://app.birja-podryada.ru/callback-request/create/";

			const myModalEl = modal
				? document.getElementById("consultBackdrop")
				: undefined;
			const consultModal = modal
				? bootstrap.Modal.getInstance(myModalEl)
				: undefined;

			const data = new FormData();
			data.append(
				"callBackForm[phone]",
				`+${formData.get("phone").replace(/\D/g, "")}`,
			);
			data.append(
				"callBackForm[name]",
				formData.get("role") + " " + formData.get("name"),
			);

			var xhr = new XMLHttpRequest();

			xhr.addEventListener("readystatechange", function () {
				if (this.readyState === 4) {
					let obj;
					try {
						obj = JSON.parse(this.responseText);
					} catch {
						obj = null;
					}
					if (obj?.isSuccess) {
						const successModalEl = document.getElementById("consultSuccess");
						const successModal = new bootstrap.Modal(successModalEl, {
							keyboard: false,
						});
						if (consultModal) consultModal.hide();
						if (successModal) {
							modalInitEvent(successModalEl);
							successModal.toggle();
						}
						event.target.reset();
					} else {
						const errorModalEl = document.getElementById("consultError");
						const errorModal = new bootstrap.Modal(errorModalEl, {
							keyboard: false,
						});
						if (consultModal) consultModal.hide();
						if (errorModal) {
							modalInitEvent(errorModalEl);
							errorModal.toggle();
						}
					}
				}
			});

			xhr.open("POST", url);
			xhr.send(data);
		};
	}
};

export const partnerFormSubmit = () => {
	const form = document.getElementById("js-partner-form-modal");

	form.onsubmit = (event) => {
		event.preventDefault();
		const formData = new FormData(form);
		const url = "https://app.birja-podryada.ru/support-request/create/";
		const myModalEl = document.getElementById("partnerBackdrop");
		const partnerModal = bootstrap.Modal.getInstance(myModalEl);

		const data = new FormData();
		data.append("supportRequestForm[email]", formData.get("email"));
		data.append(
			"supportRequestForm[header]",
			formData.get("name") +
				" " +
				`+${formData.get("phone").replace(/\D/g, "")}`,
		);
		data.append("supportRequestForm[content]", formData.get("letter"));

		var xhr = new XMLHttpRequest();

		xhr.addEventListener("readystatechange", function () {
			if (this.readyState === 4) {
				let obj;
				try {
					obj = JSON.parse(this.responseText);
				} catch {
					obj = null;
				}
				if (obj?.isSuccess) {
					const successModalEl = document.getElementById("consultSuccess");
					const successModal = new bootstrap.Modal(successModalEl, {
						keyboard: false,
					});
					if (partnerModal) partnerModal.hide();
					if (successModal) {
						modalInitEvent(successModalEl);
						successModal.toggle();
					}
					event.target.reset();
				} else {
					const errorModalEl = document.getElementById("consultError");
					const errorModal = new bootstrap.Modal(errorModalEl, {
						keyboard: false,
					});
					if (partnerModal) partnerModal.hide();
					if (errorModal) {
						modalInitEvent(errorModalEl);
						errorModal.toggle();
					}
				}
			}
		});

		xhr.open("POST", url);

		xhr.send(data);
	};
};
