export const СollapseWrapper = () => {
  document.addEventListener("DOMContentLoaded", () => {
    const collapsibleElements = document.querySelectorAll(".collapse-box");

    const closeAll = () => {
      collapsibleElements.forEach((element) => {
        const contentElement = element.querySelector(".collapse-content");
        const iconElement = element.querySelector(".collapse-icon");
  
        contentElement.classList.remove("open");
        iconElement.classList.remove("open");
      });
    }

    collapsibleElements.forEach((element) => {
      const contentElement = element.querySelector(".collapse-content");
      const titleElement = element.querySelector(".collapse-title");
      const iconElement = element.querySelector(".collapse-icon");

      if (contentElement && contentElement.children.length > 0) {
        element.style.setProperty(
          "--heightOpen",
          `${contentElement.children[0].clientHeight}px`
        );
      }

      titleElement.addEventListener("click", () => {

        if (contentElement.classList.contains("open")) {
          contentElement.classList.remove("open");
          iconElement.classList.remove("open");
        } else {
          closeAll()
          contentElement.classList.add("open");
          iconElement.classList.add("open");
        }
      });
    });

  });
};
