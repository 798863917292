export const startAppear = () => {
    document.addEventListener("DOMContentLoaded", () => {
        const elements = document.querySelectorAll(".start-appear");

        elements.forEach((element) => {
            element.classList.add("visible");
        });
    });
};

export const appearOnScroll = () => {
    document.addEventListener("DOMContentLoaded", () => {
        window.addEventListener("scroll", () => {
            const elements = document.querySelectorAll(".appear-on-scroll");
    
            elements.forEach((element) => {
                const rect = element.getBoundingClientRect();
    
                if (rect.top <= window.innerHeight && rect.bottom >= 0) {
                    element.classList.add("visible");
                } else {
                    element.classList.remove("visible");
                }
            });
        });
    });
};