export const SwiperReview = () => {
	document.addEventListener("DOMContentLoaded", () => {
		const swiper = new Swiper(".reviewsSwiper", {
			loop: true,
			freeMode: true,
			speed: 6000,
			slidesPerView: 3,
			autoplay: {
				delay: 0,
				disableOnInteraction: false,
				enabled: true,
			},
			allowTouchMove: false,

			breakpoints: {
				320: {
					slidesPerView: 1,
					spaceBetween: 1,
					freeMode: false,
					speed: 300,
					autoplay: {
						delay: 6000,
						disableOnInteraction: false,
						enabled: true,
					},
					pagination: {
						el: ".swiper-pagination",
					},
					allowTouchMove: true,
				},
				750: {
					slidesPerView: 1,
					spaceBetween: 200,
					speed: 300,
					freeMode: false,
					autoplay: {
						delay: 6000,
						disableOnInteraction: false,
						enabled: true,
					},
					pagination: {
						el: ".swiper-pagination",
					},
					allowTouchMove: true,
				},
				850: {
					slidesPerView: 2,
					spaceBetween: 30,
					speed: 6000,
					freeMode: false,
					autoplay: {
						delay: 0,
						disableOnInteraction: false,
						enabled: true,
					},
					allowTouchMove: true,
				},
				1024: {
					spaceBetween: 10,
					slidesPerView: 3,
				},
				1300: {
					spaceBetween: 10,
					slidesPerView: 4,
				},
				1900: {
					spaceBetween: 10,
					slidesPerView: 5,
				},
			},
		});

		const stopAutoplay = (swpr) => {
			const collapsibleElement = document.querySelector(".reviewsSwiper");
			let translate = 0;

			if (collapsibleElement) {
				collapsibleElement.addEventListener("mouseleave", function () {
					swpr.translateTo(translate, 6000, false, false);
				});
				collapsibleElement.addEventListener("mouseenter", function () {
					translate = swpr.translate;
					swpr.translateTo(false, 100000000, false, false);
				});
			}
		};

		if (window.innerWidth > 1000) {
			stopAutoplay(swiper);
		}
	});
};

export const SwiperCycles = () => {
	document.addEventListener("DOMContentLoaded", () => {
		const swiper = new Swiper(".cycle-swiper", {
			slidesPerView: 1,
			spaceBetween: 10,
			loop: true,
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
		});
	});
};
